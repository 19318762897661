/* .wines {
  color: #db1c24 !important;
}

.company-color {
  color: #ffdd57 !important;
} */

.about {
  background-color: #dcdcdc !important;
}

.rm-bg {
  background-color: transparent !important;
}
